import { theming } from 'shared/theme';
import { css } from '@linaria/core';

const text = css`
  font: normal 14px Euclid;
  line-height: 20px;
  margin: 0;
  &::selection {
    ${theming((theme) => ({
      background: theme.background.bg1,
    }))}
  }
  &[data-size='span'] {
    font-size: 11px;
    line-height: 14px;
  }
  &[data-size='subtext'] {
    font-size: 12.5px;
    line-height: 16px;
  }
  &[data-size='text'] {
    font-size: 14px;
    line-height: 18px;
  }
  &[data-size='h3'] {
    font-size: 17px;
    line-height: 22px;
    font-weight: 600;
  }
  &[data-size='h2'] {
    font-size: 22.5px;
    font-weight: 600;
    line-height: 28px;
  }
  &[data-size='h1'] {
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
  }

  &[data-weight='regular'] {
    font-weight: 500;
  }

  &[data-weight='light'] {
    font-weight: 300;
  }

  &[data-weight='medium'] {
    font-weight: 400;
  }

  &[data-weight='semibold'] {
    font-weight: 600;
  }

  &[data-weight='bold'] {
    font-weight: 700;
  }

  &[data-color='primary'] {
    ${theming((theme) => ({
      color: theme.text.primary,
    }))}
  }
  &[data-color='secondary'] {
    ${theming((theme) => ({
      color: theme.text.secondary,
    }))}
  }
  &[data-color='tertiary'] {
    ${theming((theme) => ({
      color: theme.text.tertiary,
    }))}
  }

  &[data-size=''] {
    * {
      font-size: inherit;
    }
  }
`;

const emptyText = css`
  border-radius: 8px;
  width: 100%;
  height: 20px;
  background-size: 200px 100%;
  background-repeat: no-repeat;
  display: inline-block;
  margin: 0;

  &[data-size='span'] {
    height: 11px;
  }
  &[data-size='subtext'] {
    height: 12.5px;
  }
  &[data-size='text'] {
    height: 14px;
  }
  &[data-size='h3'] {
    height: 17px;
  }
  &[data-size='h2'] {
    height: 22.5px;
  }
  &[data-size='h1'] {
    height: 28px;
  }

  ${theming((theme) => ({
    backgroundColor: theme.skeleton.color,
    backgroundImage: `linear-gradient(90deg, ${theme.skeleton.color}, ${theme.skeleton.highlightColor}, ${theme.skeleton.color})`,
  }))}

  @keyframes animation {
    0% {
      background-position: -200px 0;
    }

    100% {
      background-position: calc(200px + 100%) 0;
    }
  }

  animation: animation 1.2s ease-in-out infinite;
`;

export const textCss = {
  text,
  emptyText,
};
