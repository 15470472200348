import { IGetTimeSuffixOptions } from './types';

export const getTimeSuffix = (
  value: number,
  options: IGetTimeSuffixOptions,
) => {
  const time = Math.abs(value) % 100;

  if (options?.ignoreZero && value === 0) return '';

  if (time >= 11 && time <= 19) {
    return options.type === 'hour' ? `${value} часов` : `${value} минут`;
  }

  switch (time % 10) {
    case 1:
      return options.type === 'hour' ? `${value} час` : `${value} минута`;
    case 2:
    case 3:
    case 4:
      return options.type === 'hour' ? `${value} часа` : `${value} минуты`;
    default:
      return options.type === 'hour' ? `${value} часов` : `${value} минут`;
  }
};
