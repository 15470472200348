import React, { FC } from 'react';
import { importCss } from './style';
import { Text } from 'shared/ui/atoms/Text';
import { sharedCss } from 'shared/css';
import { Button } from 'shared/ui/atoms/Button';
import { TOnClick } from 'shared/types/eventHandlers';
import { useUnit } from 'effector-react';
import { menuModel } from 'pages/Menu/model';
import { GET_CSV_TEMPLATE } from 'shared/api/endpoints';

export const Import: FC = () => {
  const [file, isPending] = useUnit([
    menuModel.file.stores.$file,
    menuModel.file.pendings.$isImportPending,
  ]);

  const onUploadPattern: TOnClick<HTMLButtonElement> = (e) => {
    const inputEl = document.createElement('input');
    inputEl.type = 'file';
    inputEl.hidden = true;
    inputEl.accept = '.csv,.xls,.xlsx';

    inputEl.click();

    inputEl.onchange = () => {
      menuModel.file.events.setFile(inputEl.files[0]);
      inputEl.remove();
    };

    inputEl.oncancel = () => {
      inputEl.remove();
    };
  };

  const onImport = () => {
    menuModel.file.events.importDoc();
  };

  return (
    <div className={importCss.container}>
      <Text size="h3">Или загрузите свой excel</Text>
      <div className={importCss.content}>
        <div className={`${importCss.header} ${sharedCss.rowBetween}`}>
          <div className={importCss.headerLeft}>
            <Text size="h3">Файл excel меню</Text>
            <a
              download
              href={process.env.REACT_APP_API_HOST + GET_CSV_TEMPLATE}
              className={importCss.downloadPattern}>
              Скачать шаблон заполнения
            </a>
          </div>
          <span
            className={importCss.status}
            data-is-uploaded={Boolean(file)}>{`${
            file ? 'загружен' : 'не загружен'
          }`}</span>
        </div>
        {file && <Text size="h3">{file.name}</Text>}
        {file && (
          <Button
            onClick={onImport}
            isLoading={isPending}
            className={importCss.importButton}>
            Импортировать данные
          </Button>
        )}
        <Button styleType="secondary" fontSize="14" onClick={onUploadPattern}>
          Загрузить
        </Button>
      </div>
    </div>
  );
};
