import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const label = css`
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;

  ${theming((theme) => ({ color: theme.text.primary }))}
`;

const textAreaWrapper = css`
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 0;
`;

const leftContentWrapper = css`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
`;

const rightContentWrapper = css`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
`;

const textarea = css`
  -webkit-appearance: none;
  border: 1px solid;
  font-size: 16px;
  font-weight: 400;
  padding: 12px;
  border-radius: 8px;
  position: relative;
  outline: none;

  &[data-is-not-resized='true'] {
    resize: none;
  }

  ${theming((theme) => ({
    caretColor: theme.input.textColor,
    color: theme.input.textColor,
    borderColor: theme.input.default.borderColor,
    background: theme.input.default.background,
  }))}

  &::placeholder {
    ${theming((theme) => ({
      color: theme.input.placeholderColor,
    }))}
  }

  &:hover {
    ${theming((theme) => ({
      borderColor: theme.input.hover.borderColor,
      background: theme.input.hover.background,
    }))}
  }

  &:focus {
    outline: none;
    ${theming((theme) => ({
      borderColor: theme.input.focus.borderColor,
      background: theme.input.focus.background,
    }))}
  }

  &:disabled {
    //TODO переделать, когда в ките появится
    opacity: 0.7;
  }

  &[data-is-error='true'] {
    ${theming((theme) => ({
      borderColor: theme.input.error.borderColor,
      background: theme.input.error.background,
    }))}
  }
`;

const error = css`
  margin-top: 5px;
  padding: 0 16px;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
`;

const maxLength = css`
  ${theming((theme) => ({ color: theme.text.secondary }))}
`;

export const textAreaCss = {
  label,
  textAreaWrapper,
  leftContentWrapper,
  rightContentWrapper,
  textarea,
  error,
  maxLength,
};
