import { theming } from 'shared/theme';
import { css } from '@linaria/core';

export const labelClass = css`
  font-size: 14px;
  line-height: 26px;
`;

export const wrapperClass = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;

  &:active {
    .${labelClass} {
      opacity: 0.7;
    }
  }
`;

export const svgWrapperClass = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${theming((theme) => ({
    background: theme.checkbox.active.background,
  }))};

  svg {
    ${theming((theme) => ({
      color: `${theme.colors.white} !important`,
    }))}
  }
`;

export const checkedIconClass = css`
  width: 16px;
  height: 16px;
`;

export const checkMinusIconClass = css`
  width: 24px;
  height: 24px;
`;

export const checkboxClass = css`
  margin-right: 10px;
  min-width: 24px;
  height: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 2px solid;

  ${theming((theme) => ({
    borderColor: theme.checkbox.default.borderColor,
  }))}

  &[data-size='lg'] {
    min-width: 30px;
    height: 30px;

    .${checkedIconClass} {
      width: 18px;
      height: 18px;
    }
  }

  &[data-size='md'] {
    min-width: 24px;
    height: 24px;

    .${checkedIconClass} {
      width: 16px;
      height: 16px;
    }
  }

  &[data-size='sm'] {
    min-width: 20px;
    height: 20px;

    .${checkedIconClass} {
      width: 12px;
      height: 12px;
    }
  }

  &[data-is-rounded='true'] {
    border-radius: 50px;
  }
`;

export const inputClass = css`
  display: none;

  & + .${checkboxClass} .${svgWrapperClass} {
    transform: scale(0);
    transition: 0.1s linear;
  }

  &:checked {
    & + .${checkboxClass} {
      ${theming((theme) => ({
        borderColor: theme.checkbox.active.borderColor,
      }))}

      &[data-is-rounded='true'] {
        .${svgWrapperClass} {
          border-radius: 50px;
          transform: scale(1.1)
        }
      }

      .${svgWrapperClass} {
        border-radius: 2px;
        transform: scale(1);
      }
    }
  }

  &[data-is-secondary='true']:checked + .${checkboxClass} {
    box-shadow: none;
  }
`;
