import React, { FC, useRef, useState } from 'react';
import { IFieldProps } from './types';
import { fieldCss } from './style';
import { sharedCss } from 'shared/css';
import { Text } from 'shared/ui/atoms/Text';
import { Input } from 'shared/ui/atoms/Input';

export const Field: FC<IFieldProps> = ({
  className,
  label = '',
  value = '',
  isEditable = true,
  isStatic = false,
  inputProps,
  rightContent,
  errorKey,
  patternProps,
  onClick,
}) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const onClickEdit = () => {
    if (onClick) {
      onClick();
      return;
    }
    setIsEdit((state) => !state);
  };

  return (
    <div className={`${fieldCss.container} ${className}`}>
      {isStatic && !isEdit ? (
        <div>
          <div className={sharedCss.rowBetween}>
            {label && <Text color="secondary">{label}</Text>}
            {isEditable && (
              <button className={fieldCss.editBtn} onClick={onClickEdit}>
                изменить
              </button>
            )}
          </div>
          <div className={fieldCss.staticValueBox}>
            <Text>{value}</Text>
          </div>
        </div>
      ) : (
        <Input
          inputRef={inputRef}
          labelKey={label}
          inputProps={{ value, ...inputProps }}
          rightContent={rightContent}
          errorKey={errorKey}
          isPattern={Boolean(patternProps)}
          patternProps={patternProps}
          onValueChange={patternProps?.onValueChange}
        />
      )}
    </div>
  );
};
