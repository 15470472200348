import { DefaultTheme } from './types';

export const darkTheme: DefaultTheme = {
  type: 'dark',
  colors: {
    black: '#000000',
    white: '#ffffff',
    blue1: '#7CD4FC',
    green: '#00CC52',
    blue: '#0077FF',
    orange: '#FFAA00',
    red: '#FF0000',
  },
  text: {
    primary: '#141414',
    secondary: '#76787A',
    tertiary: '#A6C0BF',
    accent: '#0077FF',
  },
  background: {
    bg1: '#EBF0F5',
    bg2: '#ffffff',
    bg3: '#D7DBE0',
    bg4: '#000000'
  },
  defaultSpinnerColor: 'rgba(255, 255, 255, 0.2)',
  skeleton: {
    color: '#EAECEF',
    highlightColor: '#E2E4E8',
  },
  clearFieldButton: {
    primary: '#5B6477',
    secondary: '#6E798E',
    crossColor: '#CCD3E2',
  },
  checkbox: {
    default: {
      borderColor: '#76787A',
      background: 'rgba(0,119,255, 0.2)',
    },
    active: {
      borderColor: '#0077FF',
      background: '#0077FF',
    },
  },
  input: {
    default: {
      background: '#FFFFFF',
      borderColor: '#C4C8CC',
      multiValue: 'red',
    },
    hover: {
      background: '#FFFFFF',
      borderColor: '#C4C8CC',
    },
    focus: {
      background: '#FFFFFF',
      borderColor: '#0077FF',
    },
    error: {
      background: '#fff4f4',
      borderColor: '#FF0000',
    },
    textColor: '#141414',
    placeholderColor: '#C4C8CC',
  },
  button: {
    primary: {
      default: {
        background: '#141414',
        color: '#ffffff',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.1)',
      },
      hover: {
        background: '#323334',
        color: '#ffffff',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.1)',
      },
      active: {
        background: '#141414',
        color: '#ffffff',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.1)',
      },
    },
    accent: {
      default: {
        background: '#0077FF',
        color: '#ffffff',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.1)',
      },
      hover: {
        background: '#2289FF',
        color: '#ffffff',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.1)',
      },
      active: {
        background: '#006AE2',
        color: '#ffffff',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.1)',
      },
      disabled: {
        background: '#D7DBE0',
        color: '#ffffff',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.1)',
      },
    },
    secondary: {
      default: {
        background: '#EBF0F5',
        color: '#141414',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.05)',
      },
      hover: {
        background: '#F0F6FD',
        color: '#141414',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.05)',
      },
      active: {
        background: '#D9DDE3',
        color: '#ffffff',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.1)',
      },
    },
    tertiary: {
      default: {
        background: '#D7DBE0',
        color: '#76787A',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.05)',
      },
      hover: {
        background: '#DFE5EB',
        color: '#76787A',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.05)',
      },
      active: {
        background: '#CED5DE',
        color: '#ffffff',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.1)',
      },
    },
  },
  svg: {
    default: '#76787A',
    secondary: '#000000',
    disabled: '#D7DBE0'
  },
  border: {
    primary: 'rgba(0, 0, 0, 0.08)',
  },
  restaurant: {
    item: {
      background: '#ffffff',
      actionBtn: {
        background: {
          active: '#141414',
        },
      },
    },
    category: {
      background: {
        active: '#ffffff',
      },
    },
  },
  modalOverlay: 'rgba(0, 0, 0, 0.6)',
  modalShadow: '0px 10px 25px rgba(36, 65, 93, 0.2)',
  commonShadow: '0px 2px 8px rgba(255, 255, 255, 0.1)',
  commonShadow2: '0px 2px 12px rgba(255, 255, 255, 0.08)',
  commonShadow3: '0px 2px 12px rgba(255, 255, 255, 0.03)',
};

export const lightTheme: DefaultTheme = {
  type: 'light',
  colors: {
    black: '#000000',
    white: '#ffffff',
    blue1: '#7CD4FC',
    green: '#00CC52',
    blue: '#0077FF',
    orange: '#FFAA00',
    red: '#FF0000',
  },
  text: {
    primary: '#141414',
    secondary: '#76787A',
    tertiary: '#A6C0BF',
    accent: '#0077FF',
  },
  background: {
    bg1: '#EBF0F5',
    bg2: '#ffffff',
    bg3: '#D7DBE0',
    bg4: '#000000'
  },
  defaultSpinnerColor: 'rgba(255, 255, 255, 0.2)',
  skeleton: {
    color: '#EAECEF',
    highlightColor: '#E2E4E8',
  },
  clearFieldButton: {
    primary: '#5B6477',
    secondary: '#6E798E',
    crossColor: '#CCD3E2',
  },
  checkbox: {
    default: {
      borderColor: '#76787A',
      background: 'rgba(0,119,255, 0.2)',
    },
    active: {
      borderColor: '#0077FF',
      background: '#0077FF',
    },
  },
  input: {
    default: {
      background: '#FFFFFF',
      borderColor: '#C4C8CC',
      multiValue: 'red',
    },
    hover: {
      background: '#FFFFFF',
      borderColor: '#C4C8CC',
    },
    focus: {
      background: '#FFFFFF',
      borderColor: '#0077FF',
    },
    error: {
      background: '#fff4f4',
      borderColor: '#FF0000',
    },
    textColor: '#141414',
    placeholderColor: '#C4C8CC',
  },
  button: {
    primary: {
      default: {
        background: '#141414',
        color: '#ffffff',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.1)',
      },
      hover: {
        background: '#323334',
        color: '#ffffff',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.1)',
      },
      active: {
        background: '#141414',
        color: '#ffffff',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.1)',
      },
    },
    accent: {
      default: {
        background: '#0077FF',
        color: '#ffffff',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.1)',
      },
      hover: {
        background: '#2289FF',
        color: '#ffffff',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.1)',
      },
      active: {
        background: '#006AE2',
        color: '#ffffff',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.1)',
      },
      disabled: {
        background: '#D7DBE0',
        color: '#ffffff',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.1)',
      },
    },
    secondary: {
      default: {
        background: '#EBF0F5',
        color: '#141414',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.05)',
      },
      hover: {
        background: '#F0F6FD',
        color: '#141414',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.05)',
      },
      active: {
        background: '#D9DDE3',
        color: '#ffffff',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.1)',
      },
    },
    tertiary: {
      default: {
        background: '#DFE5EB',
        color: '#76787A',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.05)',
      },
      hover: {
        background: '#D7DBE0',
        color: '#76787A',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.05)',
      },
      active: {
        background: '#CED5DE',
        color: '#ffffff',
        boxShadow: '0 5px 10px rgba(255,255,255, 0.1)',
      },
    },
  },
  svg: {
    default: '#76787A',
    secondary: '#000000',
    disabled: '#D7DBE0'
  },
  border: {
    primary: 'rgba(0, 0, 0, 0.08)',
  },
  restaurant: {
    item: {
      background: '#ffffff',
      actionBtn: {
        background: {
          active: '#141414',
        },
      },
    },
    category: {
      background: {
        active: '#ffffff',
      },
    },
  },
  modalOverlay: 'rgba(0, 0, 0, 0.6)',
  modalShadow: '0px 10px 25px rgba(36, 65, 93, 0.2)',
  commonShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
  commonShadow2: '0px 2px 12px rgba(0, 0, 0, 0.08)',
  commonShadow3: '0px 2px 12px rgba(0, 0, 0, 0.03)',
};

export const colors = {
  light: lightTheme,
  dark: darkTheme,
};

export const theming = (
  cb: (allCollor: typeof colors.light) => any,
): { [key: string]: string } =>
  Object.keys(colors).reduce(
    (acc, name) =>
      Object.assign(acc, {
        [`.theme-${name} &`]: cb(colors[name]),
      }),
    {},
  );

export const themingValue = (
  cb: (allCollor: typeof colors.light) => string,
  theme: 'dark' | 'light' = 'light',
): string =>
  theme
    ? cb(colors[theme])
    : Object.keys(colors).reduce((acc, name) => cb(colors[name]), '');
