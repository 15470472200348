import React, { FC, Fragment, useEffect } from 'react';
import { Box } from '../Box';
import { CheckBox } from 'shared/ui/atoms/CheckBox';
import { TOnChange } from 'shared/types/eventHandlers';
import { timeManagerCss } from './style';
import { TServiceDeliveryTypes } from 'shared/config/enums';
import { timeManagerPickersModalModel } from 'features/TimeManagerPickersModal/model';
import { useTranslation } from 'react-i18next';
import { useForm } from 'effector-forms';
import { shopModel } from 'pages/Shop/model';
import { Field } from '../Field';
import { getTimeSuffix } from 'shared/lib/getTimeSuffix';
import { Button } from 'shared/ui/atoms/Button';
import { shopCss } from 'pages/Shop/style';
import { useUnit } from 'effector-react';

export const TimeManager: FC = () => {
  const { t } = useTranslation();
  const [isPending] = useUnit([shopModel.timeManagerForm.pendings.$isPending]);
  const { values, fields, submit } = useForm(
    shopModel.timeManagerForm.stores.$form,
  );

  useEffect(() => {
    shopModel.timeManagerForm.events.initForm();
  }, []);

  const onChange: TOnChange = (e) => {
    const name = e.target.name as TServiceDeliveryTypes;
    fields[name].onChange({ ...values[name], status: !values[name].status });
  };

  const onOpenTimePickers = (name: TServiceDeliveryTypes) => () => {
    timeManagerPickersModalModel.modal.setVisible({
      isOpen: true,
      type: name,
      value: values[name].value,
      onDone: (result) => {
        fields[name].onChange({ ...values[name], value: result });
      },
    });
  };

  const onSave = () => {
    submit();
  };

  return (
    <Box
      id="timeManager"
      title="Реализация заказов"
      contentClassName={timeManagerCss.content}>
      <div className={timeManagerCss.optionContainer}>
        <div className={timeManagerCss.option}>
          <CheckBox
            name={'pickup_enabled'}
            wrapperClassName={timeManagerCss.checkbox}
            labelKey={t(`timeManager.pickup_enabled`)}
            isChecked={values.pickup_enabled.status}
            onChange={onChange}
          />
          {!values.pickup_enabled?.value && (
            <button onClick={onOpenTimePickers('pickup_enabled')}>
              настроить
            </button>
          )}
        </div>
        {values.pickup_enabled?.value && (
          <Field
            label="Время"
            value={`с ${values.pickup_enabled.value.from} до ${values.pickup_enabled.value.to}`}
            isStatic={true}
            inputProps={{
              readOnly: true,
            }}
            onClick={onOpenTimePickers('pickup_enabled')}
          />
        )}
      </div>
      <div className={timeManagerCss.optionContainer}>
        <div className={timeManagerCss.option}>
          <CheckBox
            name={'delivery_enabled'}
            wrapperClassName={timeManagerCss.checkbox}
            labelKey={t(`timeManager.delivery_enabled`)}
            isChecked={values.delivery_enabled.status}
            onChange={onChange}
          />
          {!values.delivery_enabled?.value && (
            <button onClick={onOpenTimePickers('delivery_enabled')}>
              настроить
            </button>
          )}
        </div>
        {values.delivery_enabled?.value && (
          <Fragment>
            <Field
              label="Время"
              value={`с ${values.delivery_enabled.value.from} до ${values.delivery_enabled.value.to}`}
              isStatic={true}
              inputProps={{
                readOnly: true,
              }}
              onClick={onOpenTimePickers('delivery_enabled')}
            />
            <Field
              label="Средняя доставка"
              value={`${getTimeSuffix(
                Number(values.delivery_enabled.value.offset_time.split(':')[0]),
                { type: 'hour', ignoreZero: true },
              )} ${getTimeSuffix(
                Number(values.delivery_enabled.value.offset_time.split(':')[1]),
                { type: 'minute', ignoreZero: true },
              )}`}
              isStatic={true}
              inputProps={{
                readOnly: true,
              }}
              onClick={onOpenTimePickers('delivery_enabled')}
            />
            <Field
              label="Интервал доставки"
              value={`${getTimeSuffix(
                Number(values.delivery_enabled.value.interval.split(':')[0]),
                { type: 'hour', ignoreZero: true },
              )} ${getTimeSuffix(
                Number(values.delivery_enabled.value.interval.split(':')[1]),
                { type: 'minute', ignoreZero: true },
              )}`}
              isStatic={true}
              inputProps={{
                readOnly: true,
              }}
              onClick={onOpenTimePickers('delivery_enabled')}
            />
          </Fragment>
        )}
      </div>
      <Button
        styleType="accent"
        type="submit"
        className={shopCss.saveBtn}
        isLoading={isPending}
        onClick={onSave}>
        Сохранить
      </Button>
    </Box>
  );
};
