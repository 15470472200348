import { createForm } from 'effector-forms';
import { IWelcomeTextFormFields, TUpdatePreviewTextFx } from '../types';
import { createEffect, createEvent, sample } from 'effector';
import { servicesApi } from 'entities/Services/api';
import { servicesModel } from 'entities/Services/model';
import { EToastType, IShowToast } from 'shared/types/toast';
import { showToastEvent } from 'shared/model/toast';
import { modalFactory } from 'shared/lib/modalFactory';

const initForm = createEvent();

const $form = createForm<IWelcomeTextFormFields>({
  fields: {
    preview_text: {
      init: '',
      filter: (value) => value.length < 60,
    },
  },
  validateOn: ['change', 'submit'],
});

const updatePreviewTextFx = createEffect<TUpdatePreviewTextFx>(
  servicesApi.updateServicePreviewText,
);

const $isPending = updatePreviewTextFx.pending;

sample({
  clock: initForm,
  source: servicesModel.selectedService.stores.$service,
  fn: (service) => ({ preview_text: service?.preview_text || '' }),
  target: $form.setInitialForm,
});

sample({
  clock: $form.formValidated,
  source: servicesModel.selectedService.stores.$service,
  fn: (service, fields) => ({
    shopId: service.id,
    params: fields,
  }),
  target: updatePreviewTextFx,
});

sample({
  clock: updatePreviewTextFx.doneData,
  fn: (res) => res.data,
  target: [
    servicesModel.selectedService.events.updateService,
    servicesModel.services.events.updateService,
  ],
});

sample({
  clock: updatePreviewTextFx.doneData,
  fn: (): IShowToast => ({
    toastParams: {
      type: EToastType.Success,
      messageKey: 'Титульный текст обновлен!',
    },
  }),
  target: showToastEvent,
});

const tgModalFactory = modalFactory(false);

export const previewTextForm = {
  tgModalFactory,
  stores: {
    $form,
  },
  events: {
    initForm,
  },
  pendings: {
    $isPending,
  },
};
