import React, { FC, useEffect, useRef } from 'react';
import { PageContainer } from 'shared/ui/molecules/PageContainer';
import { Header } from 'widgets/Header';
import { shopCss } from './style';
import { BaseInfo } from './ui/BaseInfo';
import { WorkingHours } from './ui/WorkingHours';
import { BackgroundImage } from './ui/BackgroundImage';
import { Location } from './ui/Location';
import { TalegramChannel } from './ui/TalegramChannel';
import { useUnit } from 'effector-react';
import { scrollToElIdModel } from 'shared/model/scrollToElId';
import { Logo } from './ui/Logo';
import { WelcomeText } from './ui/WelcomeText';
import { TimeManager } from './ui/TimeManager';
import { Status } from './ui/Status';

export const Shop: FC = () => {
  const containerRef = useRef<HTMLDivElement>();
  const [scrollToElId] = useUnit([scrollToElIdModel.stores.$elId]);

  useEffect(() => {
    if (scrollToElId) {
      const el = document.getElementById(scrollToElId);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        el.classList.add(shopCss.boxAccentAnimation);
        setTimeout(() => {
          el.classList.remove(shopCss.boxAccentAnimation);
          scrollToElIdModel.events.resetElId();
        }, 3000);
      }
    }
  }, [scrollToElId]);

  return (
    <PageContainer
      ref={containerRef}
      id="shopSettins"
      isScrollable
      className={shopCss.container}>
      <Header scrollEl="shopSettins" hasClose={false} title="Витрина" />
      <div className={shopCss.content}>
        <Logo />
        <Status />
        <BaseInfo />
        <WorkingHours />
        <TimeManager />
        <BackgroundImage />
        <WelcomeText />
        <Location />
        <TalegramChannel />
      </div>
    </PageContainer>
  );
};
