import { createForm } from 'effector-forms';
import { TUpdateChannelFx, ITelegramFormFields } from '../types';
import { createEffect, createEvent, sample } from 'effector';
import { servicesApi } from 'entities/Services/api';
import { servicesModel } from 'entities/Services/model';
import { EToastType, IShowToast } from 'shared/types/toast';
import { showToastEvent } from 'shared/model/toast';
import { modalFactory } from 'shared/lib/modalFactory';

const initForm = createEvent();

const $form = createForm<ITelegramFormFields>({
  fields: {
    tg_channel_id: {
      init: '',
    },
  },
  validateOn: ['change', 'submit'],
});

const updateChannelFx = createEffect<TUpdateChannelFx>(
  servicesApi.updateServiceChannel,
);

const $isPending = updateChannelFx.pending;

sample({
  clock: initForm,
  source: servicesModel.selectedService.stores.$service,
  fn: (service) => ({ tg_channel_id: service?.tg_channel_id || '' }),
  target: $form.setInitialForm,
});

sample({
  clock: $form.formValidated,
  source: servicesModel.selectedService.stores.$service,
  fn: (service, fields) => ({
    shopId: service.id,
    params: {
      ...fields,
    },
  }),
  target: updateChannelFx,
});

sample({
  clock: updateChannelFx.doneData,
  fn: (res) => res.data,
  target: [
    servicesModel.selectedService.events.updateService,
    servicesModel.services.events.updateService,
  ],
});

sample({
  clock: updateChannelFx.doneData,
  fn: (): IShowToast => ({
    toastParams: {
      type: EToastType.Success,
      messageKey: 'Телеграм канал обновлен!',
    },
  }),
  target: showToastEvent,
});

const tgModalFactory = modalFactory(false);

export const telegramForm = {
  tgModalFactory,
  stores: {
    $form,
  },
  events: {
    initForm,
  },
  pendings: {
    $isPending,
  },
};
