import { css } from '@linaria/core';
import { theming } from 'shared/theme';
import { setAlpha } from 'shared/theme/lib/setAlpha';

const container = css`
  display: flex;
  flex-direction: column;
`;

const wrapper = css`
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
`;

const icFile = css`
  width: 32px;
  height: 40px;
  transition: color 0.2s ease-in-out;
  ${theming((theme) => ({ color: theme.svg.default }))}
`;

const label = css`
  margin-top: 10px;
  font-size: 16px;
  line-height: 26px;
  transition: color 0.2s ease-in-out;
`;

const fileContainer = css`
  border: 1px solid;
  height: 168px;
  width: 100%;
  min-width: 150px;
  border-radius: 14px;
  overflow: hidden;
  object-fit: cover;

  ${theming((theme) => ({
    background: theme.background.bg2,
    borderColor: theme.border.primary,
  }))}

  &:active {
    opacity: 0.7;
  }

  &[data-has-error='true'] {
    ${theming((theme) => ({
      background: setAlpha(theme.colors.red, 0.025),
    }))}

    .${wrapper} {
      ${theming((theme) => ({
        borderColor: theme.colors.red,
      }))}
    }

    .${label}, .${icFile} {
      ${theming((theme) => ({ color: theme.colors.red }))}
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const uploadFileBtn = css`
  margin-top: 12px;
  width: auto !important;
  min-height: 34px !important;

  ${theming((theme) => ({ color: `${theme.text.primary} !important` }))}
`;

const subtitle = css`
  margin-top: 12px;
`;

export const fileUploadCss = {
  wrapper,
  icFile,
  label,
  fileContainer,
  container,
  uploadFileBtn,
  subtitle
};
