import React, { FC, useEffect } from 'react';
import { Box } from '../Box';
import { telegramChannelCss } from './style';
import { Button } from 'shared/ui/atoms/Button';
import { shopModel } from 'pages/Shop/model';
import { useUnit } from 'effector-react';
import { useForm } from 'effector-forms';
import { TOnChange, TOnSubmit } from 'shared/types/eventHandlers';
import { shopCss } from 'pages/Shop/style';
import { Textarea } from 'shared/ui/atoms/Textarea';

export const WelcomeText: FC = () => {
  const [isPending] = useUnit([shopModel.previewTextForm.pendings.$isPending]);
  const { fields, errorText, submit } = useForm(
    shopModel.previewTextForm.stores.$form,
  );

  useEffect(() => {
    shopModel.previewTextForm.events.initForm();
  }, []);

  const onChange: TOnChange = (e) => {
    fields[e.target.name].onChange(e.target.value);
  };

  const onClickIntruction = () => {
    shopModel.telegramForm.tgModalFactory.setVisible(true);
  };

  const onSubmit: TOnSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  return (
    <Box
      id="welcomeText"
      title="Приветственный текст"
      subtitle="Он будет отображаться на титульном фото"
      contentClassName={telegramChannelCss.container}
      onClickHeaderAction={onClickIntruction}>
      <form onSubmit={onSubmit}>
        <Textarea
          labelKey="Содержание"
          textareaProps={{
            placeholder: 'Введите текст',
            name: 'preview_text',
            value: fields.preview_text.value,
            onChange,
          }}
          errorKey={errorText('preview_text')}
          maxLength={60}
        />
        <Button
          styleType="accent"
          type="submit"
          className={shopCss.saveBtn}
          isLoading={isPending}>
          Сохранить
        </Button>
      </form>
    </Box>
  );
};
