import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const content = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const optionContainer = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const option = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding-inline: 12px;
  min-height: 42px;

  ${theming((theme) => ({ border: `1px solid ${theme.border.primary}` }))}

  button {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;

    ${theming((theme) => ({ color: theme.text.accent }))}
  }
`;

const checkbox = css`
  height: 100%;
  flex: 1;
`;

export const timeManagerCss = {
  content,
  optionContainer,
  option,
  checkbox,
};
