import React, { FC, Fragment, useRef } from 'react';
import { logoCss } from './style';
import { Text } from 'shared/ui/atoms/Text';
import NoLogoSvg from 'shared/assets/icons/NoLogo.svg?svgr';
import CameraSvg from 'shared/assets/icons/Camera.svg?svgr';
import { servicesModel } from 'entities/Services/model';
import { useUnit } from 'effector-react';
import { imageCropModalModel } from 'features/ImageCropModal/model';
import { SpinnerBlock } from 'shared/ui/atoms/SpinnerBlock';
import { Image } from 'shared/ui/atoms/Image';

export const Logo: FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [service, isUpdatePending] = useUnit([
    servicesModel.selectedService.stores.$service,
    servicesModel.logo.pendings.$isPending,
  ]);

  const onClick = () => {
    if (!isUpdatePending) {
      inputRef.current?.click();
      inputRef.current.onchange = (e) => {
        const src = URL.createObjectURL(inputRef.current.files[0]);
        imageCropModalModel.modal.setVisible({
          isOpen: true,
          src,
          onDone: async (file) => {
            console.log(file, 'file')
            const formData = new FormData();
            formData.append('logo', file);
            console.log(formData)
            servicesModel.logo.events.updateLogo(formData);
            URL.revokeObjectURL(src);
            inputRef.current.value = ''
          },
          onCanceled: () => {},
        });
      };
    }
  };

  return (
    <div className={logoCss.container}>
      <div className={logoCss.logoBox}>
        {service?.logo ? (
          <Image className={logoCss.logo} src={service.logo} />
        ) : (
          <NoLogoSvg width={72} height={72} />
        )}
        <Text color="secondary">
          Формат: PNG; Размер: не более 2 мб; Минимальная ширина и высота 512 px
        </Text>
      </div>
      <button className={logoCss.button} type="button" onClick={onClick}>
        {isUpdatePending ? (
          <SpinnerBlock size={30} />
        ) : (
          <Fragment>
            <CameraSvg width={24} height={24} />
            Выбрать логотип для иконки Mini App
          </Fragment>
        )}
      </button>
      <input ref={inputRef} hidden type="file" accept="image/*" />
    </div>
  );
};
