import { baseInfoForm } from './baseInfoForm';
import { previewTextForm } from './previewTextForm';
import { scheduleForm } from './scheduleForm';
import { telegramForm } from './telegramForm';
import { timeManagerForm } from './timeManagerForm';

export const shopModel = {
  telegramForm,
  scheduleForm,
  baseInfoForm,
  previewTextForm,
  timeManagerForm
};
