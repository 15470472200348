import React, { FC, Fragment, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { useRoute } from 'shared/lib/useRoute';
import { LazyComponent } from 'shared/ui/molecules/LazyComponent';
import { confirmModalModel } from 'features/ConfirmModal/model';
import { dateTimePickerModalModel } from 'features/DateTimePickerModal/model';
import { mapModalModel } from 'features/MapModal/model';
import { createProductModalModel } from 'features/CreateProductModal/model';
import { servicesModel } from 'entities/Services/model';
import { useInitAuthorizedRequests } from './lib/useInitAuthorizedRequests';
import { rulesModalModel } from 'features/RulesModal/model';
import NiceModal from '@ebay/nice-modal-react';
import BottomSheet from 'shared/ui/modals/BottomSheet/BottomSheet';
import { imageCropModalModel } from 'features/ImageCropModal/model';
import { timeManagerPickersModalModel } from 'features/TimeManagerPickersModal/model';

NiceModal.register('optionsModal', BottomSheet);

const TimeManagerPickersModal = lazy(() =>
  import('features/TimeManagerPickersModal').then(
    ({ TimeManagerPickersModal }) => ({
      default: TimeManagerPickersModal,
    }),
  ),
);

const ImageCropModal = lazy(() =>
  import('features/ImageCropModal').then(({ ImageCropModal }) => ({
    default: ImageCropModal,
  })),
);

const ConfirmModal = lazy(() =>
  import('features/ConfirmModal').then(({ ConfirmModal }) => ({
    default: ConfirmModal,
  })),
);

const DateTimePickerModal = lazy(() =>
  import('features/DateTimePickerModal').then(({ DateTimePickerModal }) => ({
    default: DateTimePickerModal,
  })),
);

const MapModal = lazy(() =>
  import('features/MapModal').then(({ MapModal }) => ({
    default: MapModal,
  })),
);

const CreateProductModal = lazy(() =>
  import('features/CreateProductModal').then(({ CreateProductModal }) => ({
    default: CreateProductModal,
  })),
);

const WelcomeModal = lazy(() =>
  import('entities/Services/ui/WelcomeModal').then(({ WelcomeModal }) => ({
    default: WelcomeModal,
  })),
);

const RulesModal = lazy(() =>
  import('features/RulesModal').then(({ RulesModal }) => ({
    default: RulesModal,
  })),
);

export const RouterRoutesElement: FC = () => {
  useRoute();
  useInitAuthorizedRequests();
  return (
    <Fragment>
      <Outlet />
      <LazyComponent
        $store={confirmModalModel.modal.$store}
        withAnimation
        component={ConfirmModal}
      />
      <LazyComponent
        $store={dateTimePickerModalModel.modal.$store}
        withAnimation
        component={DateTimePickerModal}
      />
      <LazyComponent
        $store={mapModalModel.modal.$store}
        withAnimation
        component={MapModal}
      />
      <LazyComponent
        $store={createProductModalModel.modal.$store}
        withAnimation
        component={CreateProductModal}
      />
      <LazyComponent
        $store={servicesModel.welcomeModal.$store}
        withAnimation
        component={WelcomeModal}
      />
      <LazyComponent
        $store={rulesModalModel.modal.$store}
        withAnimation
        component={RulesModal}
      />
      <LazyComponent
        $store={imageCropModalModel.modal.$store}
        withAnimation
        component={ImageCropModal}
      />
      <LazyComponent
        $store={timeManagerPickersModalModel.modal.$store}
        withAnimation
        component={TimeManagerPickersModal}
      />
    </Fragment>
  );
};
