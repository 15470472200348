export enum EThemeTypes {
  dark = 'theme-dark',
  light = 'theme-light',
}

export enum EShortDays {
  'Monday' = 'Пн',
  'Tuesday' = 'Вт',
  'Wednesday' = 'Ср',
  'Thursday' = 'Чт',
  'Friday' = 'Пт',
  'Saturday' = 'Сб',
  'Sunday' = 'Вс',
}

export type TServiceDeliveryTypes = 'pickup_enabled' | 'delivery_enabled';
