import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const logoBox = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  text-align: center;

  ${theming((theme) => ({
    color: theme.text.tertiary,
  }))}
`;

const logo = css`
  width: 72px;
  height: 72px;
  border-radius: 200px;

  ${theming((theme) => ({
    boxShadow: theme.commonShadow,
  }))}
`;

const button = css`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px;
  border-radius: 12px;
  margin-top: 24px;
  width: 100%;

  ${theming((theme) => ({
    backgroundColor: theme.background.bg2,
    color: theme.colors.blue,
  }))}

  &:active {
    opacity: 0.7;
  }
`;

export const logoCss = {
  container,
  logoBox,
  logo,
  button,
};
