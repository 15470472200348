import React, { FC } from 'react';
import { switchCss } from './style';
import { clsx } from 'shared/lib/clsx';

interface ISwitchProps {
  classNames?: {
    container?: string;
    label?: string;
    input?: string;
    slider?: string;
  };
  value: boolean;
  onToggle: (value: boolean) => void;
}

export const Switch: FC<ISwitchProps> = ({ classNames, value, onToggle }) => {
  const onChange = () => {
    onToggle?.(!value);
  };
  return (
    <div className={clsx(switchCss.container, classNames?.container)}>
      <label className={clsx(switchCss.label, classNames?.label)}>
        <input
          className={clsx(switchCss.input, classNames?.input)}
          type="checkbox"
          checked={value}
          onChange={onChange}
        />
        <span className={clsx(switchCss.slider, classNames?.slider)} />
      </label>
    </div>
  );
};
