import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  border-radius: 32px 32px 0 0 !important;
  ${theming((theme) => ({
    backgroundColor: `${theme.background.bg1} !important`,
  }))}
`;

const header = css`
  .react-modal-sheet-header {
    height: auto !important;
    margin-block: 6px;

    .react-modal-sheet-drag-indicator {
      height: 5px !important;
      width: 24px !important;
      ${theming((theme) => ({
        backgroundColor: `${theme.colors.blue} !important`,
      }))}
    }
  }
`;

const content = css`
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

export const bottomSheetStyles = {
  container,
  header,
  content,
};
