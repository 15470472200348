import React, { FC } from 'react';
import { IBoxProps } from './types';
import { boxCss } from './style';
import { Text } from 'shared/ui/atoms/Text';

export const Box: FC<IBoxProps> = ({
  id,
  title = '',
  subtitle = '',
  headerContent = null,
  contentClassName = '',
  children,
  headerActionTitle = '',
  onClickHeaderAction = () => {},
}) => {
  return (
    <div id={id} className={boxCss.container}>
      {headerContent ?? (
        <div className={boxCss.header}>
          <Text size="h3">{title}</Text>
          {subtitle && <Text color="secondary">{subtitle}</Text>}
          {headerActionTitle && (
            <button
              className={boxCss.headerActionButton}
              onClick={onClickHeaderAction}>
              {headerActionTitle}
            </button>
          )}
        </div>
      )}
      {children && <div className={contentClassName}>{children}</div>}
    </div>
  );
};
