import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ITextarea } from './types';

import { textAreaCss } from './style';
import { sharedCss } from 'shared/css';

export const Textarea: FC<ITextarea> = ({
  labelKey,
  errorKey,
  classTextarea = '',
  classLabel = '',
  classWrapper = '',
  textareaProps = {},
  isNotResized = true,
  leftContent = null,
  classLeftContentWrapper = '',
  rightContent = null,
  classRightContentWrapper = '',
  errorRowCount = 1,
  isWithError = true,
  textareaRef,
  testId,
  isDefaultStyle = true,
  maxLength,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classWrapper}>
      {labelKey && (
        <label className={`${textAreaCss.label} ${classLabel}`}>
          {t(labelKey)}
        </label>
      )}
      <div className={textAreaCss.textAreaWrapper}>
        <textarea
          ref={textareaRef}
          data-is-not-resized={isNotResized}
          className={`${
            isDefaultStyle ? textAreaCss.textarea : ''
          } ${classTextarea}`}
          {...textareaProps}
          data-cy={testId}
          {...(maxLength && { maxLength })}
          data-is-error={Boolean(errorKey)}
        />
        {leftContent && (
          <div
            className={`${textAreaCss.leftContentWrapper} ${classLeftContentWrapper}`}>
            {leftContent}
          </div>
        )}
        {rightContent && (
          <div
            className={`${textAreaCss.rightContentWrapper} ${classRightContentWrapper}`}>
            {rightContent}
          </div>
        )}
      </div>
      {isWithError && errorKey && (
        <p
          className={sharedCss.errorClass}
          data-error-row-count={errorRowCount}>
          {t(errorKey)}
        </p>
      )}
      {maxLength !== undefined && (
        <p className={textAreaCss.maxLength}>
          max: {maxLength - textareaProps.value.toString().length}
        </p>
      )}
    </div>
  );
};
