import { createEffect, createEvent, sample } from 'effector';
import { createForm } from 'effector-forms';
import { ITimeManagerFormFields, TUpdateTimeManagerInfoFx } from '../types';
import { servicesModel } from 'entities/Services/model';
import { servicesApi } from 'entities/Services/api';
import { IService } from 'shared/types/api/services';
import { EToastType, IShowToast } from 'shared/types/toast';
import { showToastEvent } from 'shared/model/toast';

const initForm = createEvent();
const resetForm = createEvent();

const $form = createForm<ITimeManagerFormFields>({
  fields: {
    pickup_enabled: {
      init: {
        status: false,
        value: null,
      },
    },
    delivery_enabled: {
      init: {
        status: false,
        value: null,
      },
    },
  },
  validateOn: ['change', 'submit'],
});

const updateTimeManageInfoFx = createEffect<TUpdateTimeManagerInfoFx>(
  async ({ shopId, params }) => {
    let result = null;

    result = (
      await servicesApi.updateServiceDeliveryType({
        shopId,
        params: {
          pickup_enabled: params.pickup_enabled.status,
          delivery_enabled: params.delivery_enabled.status,
        },
      })
    ).data;

    if (params.delivery_enabled.value) {
      result = (
        await servicesApi.updateServiceDeliveryInfo({
          shopId,
          params: params.delivery_enabled.value,
        })
      ).data;
    }

    if (params.pickup_enabled.value) {
      result = (
        await servicesApi.updateServicePickupInfo({
          shopId,
          params: params.pickup_enabled.value,
        })
      ).data;
    }

    return result;
  },
);

const $isPending = updateTimeManageInfoFx.pending;

sample({
  clock: initForm,
  source: servicesModel.selectedService.stores.$service,
  filter: Boolean,
  fn: (service) => ({
    pickup_enabled: {
      status: service.pickup_enabled,
      value: service.pickup_info,
    },
    delivery_enabled: {
      status: service.delivery_enabled,
      value: service.delivery_info,
    },
  }),
  target: $form.setInitialForm,
});

sample({
  clock: $form.formValidated,
  source: servicesModel.selectedService.stores.$service,
  fn: (service, fields) => ({
    shopId: service.id,
    params: fields,
  }),
  target: updateTimeManageInfoFx,
});

sample({
  clock: updateTimeManageInfoFx.doneData,
  target: [
    servicesModel.selectedService.events.updateService,
    servicesModel.services.events.updateService,
  ],
});

sample({
  clock: updateTimeManageInfoFx.doneData,
  fn: (): IShowToast => ({
    toastParams: {
      type: EToastType.Success,
      messageKey: 'Данные реализации заказов обновлены!',
    },
  }),
  target: showToastEvent,
});

export const timeManagerForm = {
  stores: {
    $form,
  },
  events: {
    initForm,
    resetForm,
  },
  pendings: {
    $isPending,
  },
};
