import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 12px;

  ${theming((theme) => ({ backgroundColor: theme.background.bg2 }))}
`;

export const statusCss = {
  container,
};
