import { createEffect, createEvent, sample } from 'effector';
import { TUpdateServiceLogoFx } from '../types';
import { servicesApi } from '../api';
import { selectedService } from './selectedService';
import { services } from './services';
import { EToastType, IShowToast } from 'shared/types/toast';
import { showToastEvent } from 'shared/model/toast';

const updateLogo = createEvent<FormData>();
const updateLogoFx = createEffect<TUpdateServiceLogoFx>(
  servicesApi.updateServiceLogo,
);

const $isPending = updateLogoFx.pending;

sample({
  clock: updateLogo,
  source: selectedService.stores.$service,
  fn: (service, params) => ({ shopId: service.id, params }),
  target: updateLogoFx,
});

sample({
  clock: updateLogoFx.doneData,
  fn: (res) => res.data,
  target: [selectedService.events.updateService, services.events.updateService],
});

sample({
  clock: updateLogoFx.doneData,
  fn: (): IShowToast => ({
    toastParams: {
      type: EToastType.Success,
      messageKey: 'Логотип сервис обновлен!',
    },
  }),
  target: showToastEvent,
});

export const logo = {
  events: {
    updateLogo,
  },
  pendings: {
    $isPending,
  },
};
