import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const label = css`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
`;

const slider = css`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    border-radius: 50%;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
  }
`;

const input = css`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + .${slider} {
    ${theming((theme) => ({
      backgroundColor: theme.colors.blue,
    }))}/* Green when switched on */
  }

  &:checked + .${slider}:before {
    transform: translateX(16px); /* Move the button to the right */
  }
`;

export const switchCss = {
  container,
  label,
  input,
  slider,
};
