import React, { FC } from 'react';
import { Text } from 'shared/ui/atoms/Text';
import { statusCss } from './style';
import { Switch } from 'shared/ui/atoms/Switch';
import { useUnit } from 'effector-react';
import { servicesModel } from 'entities/Services/model';

export const Status: FC = () => {
  const [service, listOfPendings] = useUnit([
    servicesModel.selectedService.stores.$service,
    servicesModel.status.pendings.$listOfPendings,
  ]);
  const isPending = listOfPendings.find((id) => id === service.id);

  const onToggle = (value) => {
    servicesModel.status.events.updateStatus({
      shopId: service.id,
      params: { status: value },
    });
  };

  return (
    <div className={statusCss.container}>
      <Text>Сейчас ресторан работает</Text>
      <Switch value={service.status} onToggle={onToggle} />
    </div>
  );
};
