import React, { FC, useEffect } from 'react';
import { PageContainer } from 'shared/ui/molecules/PageContainer';
import { orderCss } from './style';
import { useParams } from 'react-router-dom';
import { ordersModel } from 'entities/Orders/model';
import { useUnit } from 'effector-react';
import { Button } from 'shared/ui/atoms/Button';
import { Svg } from 'shared/ui/atoms/Svg';
import { Header } from 'widgets/Header';
import { sharedCss } from 'shared/css';
import { Text } from 'shared/ui/atoms/Text';
import { BackButton } from 'shared/ui/atoms/BackButton';
import { NoData } from 'shared/ui/molecules/NoData';
import { ClientInfo } from './ui/ClientInfo';
import { Products } from './ui/Products';
import { getActionName } from './lib/getActionName';
import { EOrderStatus } from 'shared/types/api/orders';
import { confirmModalModel } from 'features/ConfirmModal/model';
import { useTranslation } from 'react-i18next';
import { OrderTag } from 'entities/Orders/ui/OrderTag';
import { SpinnerBlock } from 'shared/ui/atoms/SpinnerBlock';

export const Order: FC = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [order, isPending, isCanceling] = useUnit([
    ordersModel.order.stores.$data,
    ordersModel.order.pendings.$isPending,
    ordersModel.actions.stores.$isCanceling,
  ]);
  const actionInfo = getActionName(order?.status);

  useEffect(() => {
    ordersModel.order.events.getOrder(params?.id);
  }, []);

  const onRefresh = () => {
    ordersModel.order.events.getOrder(params?.id);
  };

  const onClickSetNextStatus = () => {
    ordersModel.actions.events.updateStatus(actionInfo?.nextStatus);
  };

  const onClickCancel = () => {
    confirmModalModel.modal.setVisible({
      isOpen: true,
      params: {
        title: 'orderDetails.deleteOrderTitle',
        text: 'orderDetails.deleteOrderText',
        onConfirm: () => {
          confirmModalModel.modal.resetModal();
          ordersModel.actions.events.updateStatus(EOrderStatus.CANCELED);
        },
      },
    });
  };

  return (
    <PageContainer isScrollable className={orderCss.container}>
      <Header
        scrollEl="orderDetails"
        rightContent={
          <Button
            onClick={onRefresh}
            isRounded
            styleType="tertiary"
            isLoading={isPending}>
            <Svg idIcon="icRepeat" width={24} height={24} />
          </Button>
        }
        leftContent={
          <div className={sharedCss.rowVerticalCenter}>
            <BackButton />
            {order?.order_preview_id && (
              <Text size="h2">{`№ ${order?.order_preview_id}`}</Text>
            )}
          </div>
        }
      />
      {isPending && <SpinnerBlock size={40} />}
      {!order && !isPending && <NoData title="Заказ не найден" subtitle="" />}
      {order && !isPending && (
        <div className={orderCss.content}>
          <div className={orderCss.tags}>
            <OrderTag status={order.status} />
            <OrderTag title="Доставка" variant="primary" />
            <OrderTag title="Самовывоз" variant="secondary" />
          </div>
          <div id="orderDetails" className={orderCss.scrollContent}>
            <ClientInfo
              name={`${order.userInfo.name} ${order.userInfo.surname}`}
              phone={order.userInfo.phone_number}
            />
            <Products products={order.products} />
          </div>
          {actionInfo && (
            <div className={orderCss.buttons}>
              <Button
                onClick={onClickSetNextStatus}
                size="lg"
                styleType="accent"
                isLoading={isPending && !isCanceling}
                isDisabled={isPending}>
                {t(actionInfo.name)}
              </Button>
              <Button
                styleType="tertiary"
                size="lg"
                isLoading={isPending && isCanceling}
                isDisabled={isPending}
                onClick={onClickCancel}>
                Отменить
              </Button>
            </div>
          )}
        </div>
      )}
    </PageContainer>
  );
};
