import { modalFactory } from 'shared/lib/modalFactory';
import { ITimeManagerPickersModalParams } from '../types';

export const modal = modalFactory<ITimeManagerPickersModalParams>({
  isOpen: false,
  type: null,
  value: {
    from: '',
    to: '',
    offset_time: '',
    interval: '',
  },
  onDone: () => null,
});
